import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import Logo from '../../components/Logo/Logo';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import axiosInstance from '../../services/axiosInstance';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import { authMenu } from '../../helpers/menu';
import { validatePhoneInput, validateInput } from '../../helpers/helpers';
import { productOfInterest, waterSources } from '../../helpers/options';
import LoginHeader from '../Login/LoginHeader';
import LoginFooter from '../Login/LoginFooter';
import Colors from '../../helpers/AppColors';
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import 'react-international-phone/style.css';

const listCountries = defaultCountries.filter((country) => {
	const { iso2 } = parseCountry(country);
	return ['ke', 'ug'].includes(iso2);
});


function SignUpForm() {
	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [idNumber, setIdNumber] = useState('')
	const [idNumberError, setIdNumberError] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [location, setLocation] = useState('');
	const [locationError, setLocationError] = useState('');
	const [watersource, setWaterSource] = useState('');
	const [regionId, setRegionId] = useState('')
	const [product, setProduct] = useState('');
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();

	function submitHandler(e) {
		e.preventDefault();
		setLoading(true);
		const payload = {
			firstName,
			lastName,
			mobilePhone: phoneNumber,
			regionId,
			identificationNumber: idNumber,
			referralName: 'null',
			referralPhoneNumber: 'null',
			waterSource: watersource,
			location,
			productInterested: product,
			leadSource: 'Customer Portal',
		};
		axiosInstance.post(`v1/newCustomer/salesforce`, payload).then((response) => {
			if (response.data.headers.status === true) {
				toast.success(i18n.t('signup.sucess'));
				setLoading(false)
				clearTextFields()
			} else {
				setLoading(false);
				toast.error(response.data.headers.status_message);
			}
		}).catch((error) => {
			setLoading(false);
			if (error.response && error.response.data && error.response.data.headers) {
				const errorMessage = error.response.data.headers.status_message || t('signup.resource.failed');
				toast.error(errorMessage);
			} else {
				toast.error(t('signup.resource.failed'));
			}
			clearTextFields();
		})
	}

	const clearTextFields = () => {
		setFirstName('');
		setPhoneNumber('');
		setLastName('');
		setLocation('');
		setIdNumber('')
	};

	return (
		<PageWrapper title={authMenu.signup.text} bgcolor={Colors.White}>
			<Page className='d-flex justify-content-center align-items-center m-0 p-0 py-sm-5 vh-100'>
				<div
					style={{ minHeight: '100%' }}
					className='d-flex bg-white flex-column  justify-content-between col-12 col-sm-8 col-lg-6 py-3 px-4 gap-5 rounded shadow'>
					<LoginHeader page='SignUP' />
					<div className='d-flex col-12 align-items-center flex-column'>
						<Logo />
						<div className='d-flex flex-column align-items-center my-2 '>
							<span
								style={{
									fontWeight: 'bold',
									fontSize: '22px',
									color: Colors.Black,
								}}>
								{t('signup.title')}
							</span>
						</div>
						<form onSubmit={submitHandler} className='col-12 col-lg-10 mt-4'>
							<div className='row'>
								<div className='col-md-6 mb-3'>
									<FormGroup
										id='firstName'
										name='firstName'
										isFloating={false}
										formText={
											<div style={{ color: 'red' }}>{firstNameError}</div>
										}
										label={`${t('signup.form.firstName')}*`}>
										<Input
											value={firstName}
											type='text'
											placeholder={'--'}
											onChange={(e) => {
												const firstNameValue = e.target.value
												if (firstNameValue.length === 0) {
													setFirstNameError(
														i18n.t('signup.error.msg.firstname'),
													);
												} else {
													if (firstNameValue.length <= 1) {
														setFirstNameError(
															i18n.t('signup.error.msg.firstname.invalid'),
														);
													} else {
														setFirstNameError('');
													}
												}
												setFirstName(firstNameValue);
											}}
										/>
									</FormGroup>
								</div>

								<div className='col-md-6 mb-3'>
									<FormGroup
										id='lastName'
										name='lastName'
										formText={
											<div style={{ color: 'red' }}>{lastNameError}</div>
										}
										isFloating={false}
										label={`${t('signup.form.lastName')}*`}>
										<Input
											value={lastName}
											type='text'
											placeholder={'--'}
											onChange={(e) => {
												const lastNameVariable = e.target.value
												if (lastNameVariable.length === 0) {
													setLastNameError(i18n.t('signup.error.msg.lastname'));
												} else {
													if (lastNameVariable.length <= 1) {
														setLastNameError(i18n.t('signup.error.msg.lastname.invalid'));
													} else {
														setLastNameError('');
													}
												}
												setLastName(e.target.value);
											}}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 mb-3'>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<label htmlFor="phone" style={{ fontSize: "14px", color: "#555" }}>
											{t('signup.form.phoneNumber')}*
										</label>
										<div style={{ position: "relative", width: "100%" }}>
											<PhoneInput
												defaultCountry="ke"
												placeholder='--'
												value={phoneNumber}
												className={`py-2 ${!watersource ? 'fw-light' : ''} `}
												countries={listCountries}
												onChange={(phone, { country }) => {
													const formattedPhone = phone.replace(/^\+/, "").replaceAll(" ", "")
													if (formattedPhone.length === 3) {
														setPhoneError("");
													} else if (!validatePhoneInput(formattedPhone)) {
														setPhoneError("Plese enter valid phone number");
													} else {
														setPhoneError("");
													}
													const regionIdLocation = country.iso2 === 'ke' ? "1" : country.iso2 === 'ug' ? "3" : "1"
													setRegionId(regionIdLocation)
													setPhoneNumber(formattedPhone);
												}}
												inputStyle={{
													width: "100%",
													padding: "12px",
													fontSize: "16px",
													border: "1px solid #ccc",
												}}

											/>
										</div>
										{phoneError && (
											<div style={{ color: "red", fontSize: "10px", marginTop: "0px", textAlign: 'center' }}>
												{phoneError}
											</div>
										)}
									</div>
								</div>

								<div className='col-md-6 mb-3'>
									<FormGroup
										id='idNumber'
										name='idNumber'
										formText={<div style={{ color: 'red' }}>{idNumberError}</div>}
										isFloating={false}
										label={`${t('signup.form.idNumber')}*`}>
										<Input
											min={null}
											name="idNumber"
											placeholder={'--'}
											value={idNumber}
											className={`py-2 ${!watersource ? 'fw-light' : ''} `}
											type='text'
											onChange={(e) => {
												if (e.target.value === '') {
													setIdNumberError(i18n.t('idnumber-input-digits'));
												} else if (!validateInput(e.target.value)) {
													setIdNumberError(i18n.t('idnumber-input-digits'));
												} else {
													setIdNumberError('');
												}
												setIdNumber(e.target.value);
											}}
										/>
									</FormGroup>
								</div>

							</div>
							<div className='row'>

								<div className='col-md-6 mb-3'>
									<FormGroup
										id='location'
										name='location'
										formText={
											<div style={{ color: 'red' }}>{locationError}</div>
										}
										isFloating={false}
										label={`${t('label.location')}*`}>
										<Input
											value={location}
											type='text'
											placeholder="--"
											onChange={(e) => {
												if (e.target.value.length === 0) {
													setLocationError(i18n.t('signup.error.msg.location'));
												} else {
													setLocationError('');
												}
												setLocation(e.target.value);
											}}
										/>
									</FormGroup>
								</div>
								<div className='col-md-6 mb-3'>
									<label htmlFor="phone" style={{ fontSize: "14px", color: "#555" }}>
										{t('label.select.watersource')}*
									</label>
									<FormGroup id='water_source' name='water_source'>
										<Select
											className={`py-2 ${!watersource ? 'fw-white' : ''} `}
											firstName='water_source'
											value={watersource}
											onChange={(e) => {
												setWaterSource(e.target.value);
											}}>
											<option value='' disabled>
												{`--`}
											</option>
											{waterSources.map((d, i) => {
												return (
													<option key={i} value={d.key}>
														{d.value}
													</option>
												);
											})}
										</Select>
									</FormGroup>
								</div>
							</div>
							<div className='row'>

								<div className='col-md-12 mb-3'>
									<label htmlFor="phone" style={{ fontSize: "14px", color: "#555" }}>
										{t('label.select.product')}*
									</label>
									<FormGroup>
										<Select
											className={`py-2 ${!product ? 'fw-light' : ''} `}
											firstName='PROD'
											value={product}
											onChange={(e) => setProduct(e.target.value)}>
											<option value='' disabled>
												{`--`}
											</option>
											{productOfInterest.map((d) => {
												return <option value={d.value}>{d.value}</option>;
											})}
										</Select>
									</FormGroup>
								</div>
							</div>

							<div className='d-flex align-items-center justify-content-center my-3'>
								<button
									disabled={
										lastName.length <= 1 ||
										firstName.length <= 1 ||
										!validatePhoneInput(phoneNumber) ||
										!validateInput(idNumber) ||
										location.length <= 1 ||
										watersource === '' ||
										product === '' ||
										regionId === ''
									}
									style={{
										background:
											lastName.length <= 1 ||
												firstName.length <= 1 ||
												!validatePhoneInput(phoneNumber) ||
												!validateInput(idNumber) ||
												location.length <= 1 ||
												watersource === '' ||
												product === '' ||
												regionId === ''
												? Colors.Gray // Use a gray color for disabled
												: Colors.Brand, // Use the brand color for enabled
										borderRadius: '12px',
										color: Colors.White,

									}}
									type="submit"
									className="btn col-sm-10 col-md-8 col-12 py-3"
									data-testid="Submit">
									{loading ? (
										<div
											className="spinner-border spinner-border-sm text-light"
											role="status">
											<span className="visually-hidden">Loading...</span>
										</div>
									) : (
										t('signup.submit')
									)}
								</button>

							</div>
						</form>
						<div
							className='d-flex align-items-center justify-content-center mt-2 p-2'
							style={{
								color: Colors.Black,
							}}>
							{t('login.account.already')}
							<Link
								to='/'
								style={{
									marginLeft: '3px',
									fontWeight: 600,
									outline: 'none',
									color: Colors.Green,
									textDecoration: 'none',
								}}>
								{t('login.here')}
							</Link>
						</div>
					</div>
					<LoginFooter />
				</div>
			</Page>
		</PageWrapper>
	);
}

export default SignUpForm;
